import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/lead_reducer";
import { GET_LEAD_BEGIN, GET_LEAD_ERROR, GET_LEAD_SUCCESS } from "../actions";

const LeadContext = React.createContext();

const initialState = {
  lead_loading: false,
  lead_error: false,
  error_code: 200,
  leads: [],
  product: "lauditor",
};

export const LeadProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_LEAD_REST_ENDPOINT;
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchLead = async (url, product) => {
    dispatch({ type: GET_LEAD_BEGIN });
    try {
      const leadUrl =
        product === "" ? `${restUrl}/` : `${restUrl}/${product.toUpperCase()}`;
      const response = await axios.get(leadUrl);
      if (response.status !== undefined) {
        if (response.status === 200) {
          const leads = response;
          dispatch({ type: GET_LEAD_SUCCESS, payload: leads });
        } else {
          dispatch({
            type: GET_LEAD_ERROR,
            payload: { error_code: response.status, lead_error: true },
          });
        }
      } else {
        dispatch({
          type: GET_LEAD_ERROR,
          payload: { error_code: response.status, lead_error: true },
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: GET_LEAD_ERROR,
          payload: { error_code: error.response.status, lead_error: true },
        });
      } else {
        dispatch({
          type: GET_LEAD_ERROR,
          payload: { error_code: 501, lead_error: true },
        });
      }
    }
  };

  return (
    <LeadContext.Provider value={{ ...state, fetchLead }}>
      {children}
    </LeadContext.Provider>
  );
};

export const useLeadContext = () => {
  return useContext(LeadContext);
};
