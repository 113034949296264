import React, { useEffect } from "react";
import { useLeadFilterContext } from "../context/lead_filter_context";
import { useLoginContext } from "../context/login_context";
import TopNav from "./TopNav";
import Error from "./Error";
import Loading from "./Loading";
import LeadFilter from "./LeadFilter";
import { useLocation, useNavigate } from "react-router-dom";
import LeadRow from "./LeadRow";

const Leads = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshData, showMessage, message, plan } = location.state;

  const { loggedIn, user } = useLoginContext();
  const {
    lead_loading: loading,
    lead_error: error,
    error_code: error_code,
    filtered_leads: filtered_leads,
    reloadData,
  } = useLeadFilterContext();

  useEffect(() => {
    if (error && error_code !== 200) {
      // sessionStorage.setItem("latoken", "");
      navigate("/login");
    }
  }, [error_code, error]);

  useEffect(() => {
    // console.log(fromSource)
    // if (refreshData) {
    //console.log("reloading data")
    reloadData(plan ? plan.product : plan);
    //}
  }, [refreshData]);
  return (
    <>
      <TopNav loggedIn={loggedIn} user={user} />
      <div className="section section-center">
        <div className="title">Leads</div>
        <LeadFilter />
        <table>
          <thead>
            <tr>
              <th>Firm Name</th>
              <th>Contact Person</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Enquired On</th>
              <th>Product</th>
            </tr>
          </thead>
          <tbody>
            {filtered_leads.map((lead) => {
              return <LeadRow key={lead._id} {...lead} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Leads;
