import React from 'react'
import { useProfFilterContext } from '../context/prof_filter_context'
import { useGlobalContext } from '../context/global_context'
import { FaSearch, FaPlus, FaFileCsv, FaDownload} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";

const Proffilter = () => {
  const {
    filters: {
        text, category, filterproduct
    }, 
    changeProduct,
    updateProfFilters, 
    clearProfFilters,
    filtered_professionals,
    prof_emails
  } = useProfFilterContext()

  const {
    products
  } = useGlobalContext()

  return (
    <>
    <section >
        <form  onSubmit={(e)=>{e.preventDefault()}}>
            {/* Search Input */}
            <div className="form-control">
                <select className='search-input float-left' id="filterproduct" name="filterproduct" autoFocus value={filterproduct} onChange={changeProduct} required>
                   {products.map((product)=> {
                      return <option key={product[1]} value={product[1]}>{product[0]}</option>
                    })}
                  </select>
                <Link to="/profadd">
                  <button className='btn-small hot float-left'>Add New <FaPlus /></button>
                  </Link>
                  <CSVLink className='btn-small hot float-left' data={prof_emails} filename={"proemails.csv"}>
                   Emails <FaDownload/>
                  </CSVLink>
                <div className='float-right'>
                  <CSVLink data={filtered_professionals} filename={"professionals.csv"}><FaFileCsv/></CSVLink>&nbsp;&nbsp;
                  <FaSearch/>&nbsp;&nbsp;
                  <select className='search-input' name="category" id="category" value={category} onChange={updateProfFilters} >
                      <option value="all">all</option>
                      <option value="fname">Firm Name</option>
                      <option value="cperson">Contact Person</option>
                      <option value="email">Email</option>
                      <option value="plan">Plan (Product)</option>
                  </select>
                  <input type="text" 
                  name="text" id="text" 
                  placeholder='search' 
                  className='search-input' 
                  autoFocus
                  value={text} 
                  onChange={updateProfFilters} />
                  <button className='btn-small float-right hot' onClick={clearProfFilters}>clear filter</button>
                </div>
            </div>
            
        </form>
        
    </section>
    <br /><br /><br/>
    
    <span className='float-right'>Search found {filtered_professionals.length} rows</span>
    </>
  )
}

export default Proffilter