import React, { useEffect } from "react";
import { useConsumerFilterContext } from "../context/consfilter_context";
import { useLoginContext } from "../context/login_context";
import Error from "./Error";
import Loading from "./Loading";
import TopNav from "./TopNav";
import ConsumerRow from "./ConsumerRow";
import Consfilter from "./Consfilter";
import { useLocation, useNavigate } from "react-router-dom";

const Consumer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshData, showMessage, message } = location.state;
  const {
    consumer_loading: loading,
    consumer_error: error,
    eroor_code: error_code,
    filtered_consumers: filtered_consumers,
    reloadData,
  } = useConsumerFilterContext();

  const { loggedIn, user } = useLoginContext();

  useEffect(
    () => {
      if (error && error_code !== 200) {
        // sessionStorage.setItem("latoken", "");
        navigate("/login");
      }
    },
    error_code,
    error
  );
  //Reload Fresh Data calling Rest API when fromSource is True
  useEffect(() => {
    // console.log(fromSource)
    if (refreshData) {
      //console.log("reloading data")
      reloadData();
    }
  }, [refreshData]);

  // console.log(filtered_consumers)
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <>
      <TopNav loggedIn={loggedIn} user={user} />
      <div className="section section-center">
        <div className="title">Consumers</div>

        <Consfilter />
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {filtered_consumers.map((consumer) => {
              return <ConsumerRow key={consumer.id} {...consumer} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Consumer;
