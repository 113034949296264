import axios from "axios";
import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/modifyprod_reducer";

import {
  MODIFY_PRODUCT,
  UPDATE_PROF_ID,
  GET_PROF_DETAIL_BEGIN,
  GET_PROF_DETAIL_SUCCESS,
  GET_PROF_DETAIL_ERROR,
  SAVE_SUCCESS,
  RESET_PROD_STATE,
} from "../actions";

const initialState = {
  prof_loading: false,
  prof_error: false,
  product: "",
  profid: "",
  profUser: null,
  savedStatus: false,
};

const ModifyProdContext = React.createContext();

export const ModifyProdProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchProfDetail(`${restUrl}/professional`);
  }, [state.profid]);

  const fetchProfDetail = async (url) => {
    const bearer_token = sessionStorage.getItem("latoken");
    const prodUrl = `${url}/${state.profid}`;
    dispatch({ type: GET_PROF_DETAIL_BEGIN });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(prodUrl, config);
      const profUserData = await response.data.details;
      dispatch({ type: GET_PROF_DETAIL_SUCCESS, payload: profUserData });
    } catch (error) {
      dispatch({ type: GET_PROF_DETAIL_ERROR });
    }
  };

  const updateId = (id) => {
    dispatch({ type: UPDATE_PROF_ID, payload: { id } });
  };

  const updateProduct = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: MODIFY_PRODUCT, payload: { name, value } });
  };

  const resetStateValues = () => {
    dispatch({ type: RESET_PROD_STATE, payload: { savedStatus: false } });
  };

  const saveProduct = async () => {
    const bearer_token = sessionStorage.getItem("latoken");
    const prodUrl = `${restUrl}/professional/${state.profid}`;
    dispatch({ type: GET_PROF_DETAIL_BEGIN });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.put(
        prodUrl,
        { plan: state.product },
        config
      );
      const profUserData = await response.data;
      //   console.log(response);
      dispatch({ type: SAVE_SUCCESS, payload: profUserData });
    } catch (error) {
      dispatch({ type: GET_PROF_DETAIL_ERROR });
    }
  };

  return (
    <ModifyProdContext.Provider
      value={{
        ...state,
        updateProduct,
        updateId,
        saveProduct,
        resetStateValues,
      }}
    >
      {children}
    </ModifyProdContext.Provider>
  );
};

export const useModifyProdContext = () => {
  return useContext(ModifyProdContext);
};
