import { LOGIN, UPDATE_LOGIN_VALUE, LOGIN_ERROR } from "../actions";

const login_reducer = (state, action) => {
  if (action.type === UPDATE_LOGIN_VALUE) {
    const { name, value } = action.payload;
    return { ...state, [name]: value, loggedIn: false };
  } else if (action.type === LOGIN) {
    const { loggedIn, message, token } = action.payload;
    return { ...state, loggedIn: loggedIn, message: message, token: token };
  } else if (action.type === LOGIN_ERROR) {
    return { ...state, loggedIn: false };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default login_reducer;
