import React from "react";
import { Link } from "react-router-dom";

const TopNav = (props) => {
  const { loggedIn, user } = props;
  return (
    <nav className="top-nav">
      <div className="logo">
        <Link to="/">Coffer Admin</Link>
      </div>
      {/* <!-- <button className="hamburger" type="button" aria-label="Open Navigation">
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button> --> */}
      <ul className="nav-links">
        <li>
          <Link
            to="/consumer"
            state={{ refreshData: true, showMessage: false, message: "" }}
          >
            Consumers
          </Link>
        </li>
        <li>
          <Link
            to="/professional"
            state={{ refreshData: true, showMessage: false, message: "" }}
          >
            Professionals
          </Link>
        </li>
        <li>
          <Link
            to="/leads"
            state={{ refreshData: true, showMessage: false, message: "" }}
          >
            Leads
          </Link>
        </li>
        {/* {user && (
          <li>
            <Link to="/login?action=logout">Logout</Link>
          </li>
        )} */}
        {!user && (
          <li>
            <Link to="/login">Login</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default TopNav;
