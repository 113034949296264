import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_PROD_SUCCESS,
  GET_PROD_ERROR,
  GET_PROFCOUNT_SUCCESS,
  GET_PROFCOUNT_ERROR,
  GET_CONSCOUNT_SUCCESS,
  GET_CONSCOUNT_ERROR,
} from "../actions";

const global_reducer = (state, action) => {
  if (action.type === GET_COUNTRIES_SUCCESS) {
    return { ...state, countries: action.payload };
  } else if (action.type === GET_COUNTRIES_ERROR) {
    //need to handle more code here for errors, possibly send error messages
    return { ...state };
  } else if (action.type === GET_PROD_SUCCESS) {
    //const {products} = action.payload
    return { ...state, products: action.payload };
  } else if (action.type === GET_PROD_ERROR) {
    //need to handle more code here for errors, possibly send error messages
    return { ...state };
  } else if (action.type === GET_PROFCOUNT_SUCCESS) {
    //need to handle more code here for errors, possibly send error messages
    return { ...state, profCount: action.payload };
  } else if (action.type === GET_PROFCOUNT_ERROR) {
    //need to handle more code here for errors, possibly send error messages
    return { ...state };
  } else if (action.type === GET_CONSCOUNT_SUCCESS) {
    //need to handle more code here for errors, possibly send error messages
    return { ...state, consCount: action.payload };
  } else if (action.type === GET_CONSCOUNT_ERROR) {
    //need to handle more code here for errors, possibly send error messages
    return { ...state };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default global_reducer;
