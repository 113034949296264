import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useModifyProdContext } from "../context/modifyprod_context";
import { useLoginContext } from "../context/login_context";
import { useGlobalContext } from "../context/global_context";
import TopNav from "./TopNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ModifyProduct = () => {
  const navigate = useNavigate();
  const {
    prof_loading: loading,
    prof_error: error,
    product,
    profid,
    profUser: userDetail,
    savedStatus,
    updateProduct,
    updateId,
    saveProduct,
    resetStateValues,
  } = useModifyProdContext();

  const { products } = useGlobalContext();

  const { loggedIn, user } = useLoginContext();

  const { proid } = useParams();

  useEffect(() => {
    if (savedStatus) {
      //navigate("/professional");
      resetStateValues();
      navigate("/professional", {
        state: {
          refreshData: true,
          showMessage: true,
          message: "Saved Successfully!",
          plan: userDetail.plan,
        },
      });
      // history.replace('/professional')
    }
  }, [savedStatus]);

  useEffect(() => {
    updateId(proid);
  }, [proid]);

  const cancelClick = () => {
    navigate("/professional", {
      state: { refreshData: false, showMessage: false, message: "" },
    });
  };

  const notify = () => toast.success("Saved Successfully");
  return (
    <>
      <TopNav loggedIn={loggedIn} user={user} />
      <div className="section section-center">
        {/* {savedStatus ? '<div><ToastContainer /></div>' : ''} */}
        <ToastContainer hideProgressBar={true} theme="colored" />
        <div className="title">Modify Professional</div>

        <form
          className="mod-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {/* Search Input */}
          <div className="form-group">
            <label className="mod-label" htmlFor="name">
              Firm Name
            </label>
            <input
              className="mod-input"
              type="text"
              id=""
              name=""
              value={userDetail ? userDetail.name : ""}
              readOnly
            />
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="cperson">
              Contact Person
            </label>
            <input
              className="mod-input"
              type="text"
              id=""
              name=""
              value={userDetail ? userDetail.contact_person : ""}
              readOnly
            />
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="email">
              Email
            </label>
            <input
              className="mod-input"
              type="email"
              id=""
              name=""
              value={userDetail ? userDetail.email : ""}
              readOnly
            />
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="product">
              Product
            </label>
            <select
              className="mod-select"
              id="product"
              name="product"
              autoFocus
              value={product ? product : userDetail ? userDetail.plan : ""}
              onChange={updateProduct}
              required
            >
              {products.map((product) => {
                return (
                  <option key={product[1]} value={product[1]}>
                    {product[0]}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="button-container">
            <button className="btn-small hot" onClick={saveProduct}>
              Save
            </button>
            <button className="btn-small cold" onClick={cancelClick}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ModifyProduct;
