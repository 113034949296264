import axios from "axios";
import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/global_reducer";

import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_PROD_SUCCESS,
  GET_PROD_ERROR,
  GET_PROFCOUNT_SUCCESS,
  GET_PROFCOUNT_ERROR,
  GET_CONSCOUNT_SUCCESS,
  GET_CONSCOUNT_ERROR,
} from "../actions";

const initialState = {
  countries: [],
  products: [],
  profCount: 0,
  consCount: 0,
};

const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const bearer_token = sessionStorage.getItem("latoken");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchCountries(restUrl);
    fetchProducts(restUrl);
  }, []);

  const fetchProducts = async (url) => {
    const prodUrl = `${url}/products`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(prodUrl, config);
      const products = await response.data.data.products;
      dispatch({ type: GET_PROD_SUCCESS, payload: products });
    } catch (error) {
      dispatch({ type: GET_PROD_ERROR });
    }
  };

  const fetchProfCount = async (url) => {
    const bearer_token = sessionStorage.getItem("latoken");
    const prodUrl = `${url}/professionalcount`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(prodUrl, config);
      const profCount = await response.data.count;
      dispatch({ type: GET_PROFCOUNT_SUCCESS, payload: profCount });
    } catch (error) {
      dispatch({ type: GET_PROFCOUNT_ERROR });
    }
  };

  const fetchCusCount = async (url) => {
    const bearer_token = sessionStorage.getItem("latoken");
    const prodUrl = `${url}/consumercount`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(prodUrl, config);
      const cusCount = await response.data.count;
      dispatch({ type: GET_CONSCOUNT_SUCCESS, payload: cusCount });
    } catch (error) {
      dispatch({ type: GET_CONSCOUNT_ERROR });
    }
  };

  const fetchCountries = async (url) => {
    const countryUrl = `${url}/countries`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(countryUrl, config);
      let data = await response.data.data.countries;
      if (data.length >= 1) {
        if (data[0][0] === "select") {
          data[0][0] = "";
        }
      }
      dispatch({ type: GET_COUNTRIES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GET_COUNTRIES_ERROR });
    }
  };
  return (
    <GlobalContext.Provider value={{ ...state, fetchCusCount, fetchProfCount }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
