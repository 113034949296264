import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAddProfContext } from "../context/addprof_context";
import { useLoginContext } from "../context/login_context";
import { useGlobalContext } from "../context/global_context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import TopNav from "./TopNav";

const ProfessionalForm = () => {
  const navigate = useNavigate();
  const {
    fname,
    email,
    phone,
    country,
    product,
    cperson,
    sub_model,
    savedStatus,
    prof_error,
    message,
    updateInputValue,
    saveProfessional,
    resetStateValues,
  } = useAddProfContext();

  const { loggedIn, user } = useLoginContext();

  const { countries, products } = useGlobalContext();

  useEffect(() => {
    if (savedStatus) {
      //navigate("/professional");
      if (prof_error) {
        resetStateValues();
        notify(prof_error, message);
      } else {
        resetStateValues();
        navigate("/professional", {
          state: { refreshData: true, showMessage: true, message: message },
        });
      }
      // history.replace('/professional')
    }
  }, [savedStatus]);

  const cancelClick = () => {
    navigate("/professional", {
      state: { refreshData: false, showMessage: false, message: "" },
    });
  };

  const notify = (error, msg) => {
    if (error) toast.error(msg);
    else toast.success(msg);
  };
  return (
    <>
      <TopNav loggedIn={loggedIn} user={user} />
      <div className="section section-center">
        {/* {savedStatus ? '<div><ToastContainer /></div>' : ''} */}
        <ToastContainer hideProgressBar={true} theme="colored" />
        <div className="title">Add Professional</div>

        <form
          className="mod-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="form-group">
            <label className="mod-label" htmlFor="name">
              Firm Name
            </label>
            <input
              className="mod-input"
              type="text"
              id="fname"
              name="fname"
              autoFocus
              value={fname}
              onChange={updateInputValue}
              required
            />
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="email">
              Email
            </label>
            <input
              className="mod-input"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={updateInputValue}
              required
            />
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="country">
              Country
            </label>
            <select
              className="mod-select"
              id="country"
              name="country"
              value={country}
              onChange={updateInputValue}
              required
            >
              {countries.map((country) => {
                return (
                  <option key={country[1]} value={country[1]}>
                    {country[0]}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="cperson">
              Contact Person
            </label>
            <input
              className="mod-input"
              type="text"
              id="cperson"
              name="cperson"
              value={cperson}
              onChange={updateInputValue}
              required
            />
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="product">
              Product
            </label>
            <select
              className="mod-select"
              id="product"
              name="product"
              value={product}
              onChange={updateInputValue}
              required
            >
              {/* <option value="">Please select a Product</option> */}
              {products.map((product) => {
                return (
                  <option key={product[1]} value={product[1]}>
                    {product[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="phone">
              Phone
            </label>
            <input
              className="mod-input"
              type="text"
              id="phone"
              name="phone"
              value={phone}
              onChange={updateInputValue}
              required
            />
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="sub_model">
              Subscription Model
            </label>
            <select
              className="mod-select"
              id="sub_model"
              name="sub_model"
              value={sub_model}
              onChange={updateInputValue}
              required
            >
              <option key="free" value="free">
                Free
              </option>
              <option key="paid" value="paid">
                Paid
              </option>
            </select>
          </div>
          <div className="button-container">
            <button className="btn-small hot" onClick={saveProfessional}>
              Save
            </button>
            <button className="btn-small cold" onClick={cancelClick}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProfessionalForm;
