import React, { useEffect } from "react";
import TopNav from "./TopNav";
import { useLoginContext } from "../context/login_context";
import { useGlobalContext } from "../context/global_context";
import CardLayout from "./CardLayout";

const Home = () => {
  const { loggedIn, user } = useLoginContext();
  const {
    profCount,
    consCount,
    fetchProfCount,
    fetchCusCount,
  } = useGlobalContext();

  useEffect(() => {
    fetchProfCount(process.env.REACT_APP_REST_ENDPOINT);
    fetchCusCount(process.env.REACT_APP_REST_ENDPOINT);
  }, []);

  return (
    <>
      <div>
        <TopNav loggedIn={loggedIn} user={user} />
      </div>
      <div>
        <CardLayout cusCount={consCount} profCount={profCount} />
      </div>
    </>
  );
};

export default Home;
