import React from "react";
import { useLoginContext } from "../context/login_context";
import { Routes, Route, Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, ...rest }) => {
  const { loggedIn, user, token } = useLoginContext();
  //console.log(rest)
  if (!user || token === "") {
    return <Navigate to="/login" />;
  }
  return children;
  // return (
  // <Routes>
  //   <Route {...rest} render={()=>{
  //     return (user ? children : <Navigate to="/" />)
  //   }}>

  //   </Route>
  //   </Routes>
  // )
};
export default ProtectedRoute;
