import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Consumer from "./components/Consumer";
import Professional from "./components/Professional";
import ProfessionalForm from "./components/ProfessionalForm";
import ModifyProduct from "./components/ModifyProduct";
import ProtectedRoute from "./components/ProtectedRoute";
import Proffilter from "./components/Proffilter";
import Leads from "./components/Leads";

// import { Consumer } from "./components";

const App = () => {
  const [user, setUser] = useState(null);
  return (
    // <Menu />
    <Router forceRefresh={true}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/consumer"
          element={
            <ProtectedRoute user={user}>
              <Consumer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/professional"
          element={
            <ProtectedRoute user={user}>
              <Professional />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <ProtectedRoute user={user}>
              <Leads />
            </ProtectedRoute>
          }
        />
        {/* <Route path="/prodedit" element={<ModifyProduct/>} /> */}
        <Route
          path="/profadd"
          element={
            <ProtectedRoute user={user}>
              <ProfessionalForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/prodedit"
          element={
            <ProtectedRoute user={user}>
              <ModifyProduct />
            </ProtectedRoute>
          }
        >
          <Route index element={<Professional />} />
          <Route
            path=":proid"
            element={
              <ProtectedRoute user={user}>
                <ModifyProduct />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
