import {
  LOAD_LEADS,
  FILTER_LEAD,
  UPDATE_LEAD_FILTER,
  UPDATE_PRODUCT_FILTER,
  CLEAR_LEAD_FILTER,
} from "../actions";

const lead_filter_reducer = (state, action) => {
  if (action.type === FILTER_LEAD) {
    const { lead_error, error_code } = action.payload;
    const { all_leads } = state;
    const { text, category } = state.filters;
    let tempLeads = [...all_leads];
    // console.log(`category = ${category}`);
    if (text) {
      if (category === "email") {
        tempLeads = tempLeads.filter((lead) => {
          return lead.email.toLowerCase().startsWith(text);
        });
      } else if (category === "fname") {
        tempLeads = tempLeads.filter((lead) => {
          return lead.firmname.toLowerCase().startsWith(text);
        });
      } else if (category === "cperson") {
        tempLeads = tempLeads.filter((lead) => {
          return lead.person.toLowerCase().startsWith(text);
        });
      } else if (category === "phone") {
        tempLeads = tempLeads.filter((lead) => {
          return lead.phone.toLowerCase().startsWith(text);
        });
      } else if (category === "plan") {
        tempLeads = tempLeads.filter((lead) => {
          return lead.plan.toLowerCase().startsWith(text);
        });
      } else {
        tempLeads = tempLeads.filter((lead) => {
          return (
            lead.firmname.toLowerCase().startsWith(text) ||
            lead.person.toLowerCase().startsWith(text) ||
            lead.email.toLowerCase().startsWith(text) ||
            lead.phone.toLowerCase().startsWith(text) ||
            lead.plan.toLowerCase().startsWith(text)
          );
        });
      }
    }
    return {
      ...state,
      filtered_leads: tempLeads,
      lead_error: lead_error,
      error_code: error_code,
    };
  } else if (action.type === LOAD_LEADS) {
    const payload = action.payload;
    return {
      ...state,
      all_leads: payload.data,
      filtered_leads: payload.data,
      filters: {
        ...state.filters,
      },
      error_code: payload.error_code,
      prof_error: payload.prof_error,
    };
  } else if (action.type === CLEAR_LEAD_FILTER) {
    return {
      ...state,
      filters: { ...state.filters, text: "", category: "all" },
      prof_error: false,
      error_code: 200,
    }; //to be coded
  } else if (action.type === UPDATE_LEAD_FILTER) {
    const { name, value } = action.payload;
    // console.log(`name = ${name} value = ${state.filters}`);
    return {
      ...state,
      filters: { ...state.filters, [name]: value },
      error_code: 200,
      prof_error: false,
    };
  } else if (action.type === UPDATE_PRODUCT_FILTER) {
    // const { name, value } = action.payload;
    // console.log(`name = ${name} value = ${state.filters}`);
    return {
      ...state,
      filters: {
        ...state.filters,
        filterproduct: action.payload,
        error_code: 200,
        prof_error: false,
      },
    };
  }
  //return state
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default lead_filter_reducer;
