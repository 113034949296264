import React from "react";
import TopNav from "../components/TopNav";
import { useLoginContext } from "../context/login_context";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";

const Login = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const action = searchParams.get("action") || ""; // 'name'
  const {
    uname,
    password,
    loggedIn,
    message,
    token,
    user,
    login,
    updateInputValue,
    setuser,
    settoken,
    setmessage,
  } = useLoginContext();

  // useEffect(() => {
  //   if (searchParams.get("action") === "logout") {
  //     setuser(null);
  //     settoken("");
  //   }
  // }, []);

  useEffect(() => {
    if (loggedIn) {
      setuser({ uname: uname });
      settoken(token);
      setmessage("");
      navigate("/");
    } else {
      setuser(null);
      settoken("");
      if (message !== "") {
        notify(message);
        setmessage("");
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
        //
      }
    }
  }, [loggedIn, message]);
  const notify = (msg) => toast.error(msg);
  return (
    <>
      <TopNav loggedIn={loggedIn} user={user} />

      <div className="section section-center">
        <div className="title">Login</div>
        <ToastContainer hideProgressBar={true} theme="colored" />
        <form
          className="mod-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="form-group">
            <label className="mod-label" htmlFor="uname">
              User Name
            </label>
            <input
              className="mod-input"
              type="text"
              id="uname"
              name="uname"
              autoFocus
              value={uname}
              onChange={updateInputValue}
              required
            />
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="password">
              Password
            </label>
            <input
              className="mod-input"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={updateInputValue}
              required
            />
          </div>
          <div className="button-container">
            <button className="btn-small hot" onClick={login}>
              Login
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
