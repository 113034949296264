import {
  LOAD_CONSUMERS,
  FILTER_CONS,
  UPDATE_CONS_FILTER,
  CLEAR_CONS_FILTER,
  UNAUTHORIZED,
} from "../actions";

const consumer_filter_reducer = (state, action) => {
  if (action.type === FILTER_CONS) {
    const { all_consumers } = state;
    const { text, category } = state.filters;
    let tempConsumers = [...all_consumers];
    if (text) {
      if (category === "email") {
        tempConsumers = tempConsumers.filter((consumer) => {
          return consumer.email.toLowerCase().startsWith(text);
        });
      } else if (category === "fname") {
        tempConsumers = tempConsumers.filter((consumer) => {
          return consumer.first_name.toLowerCase().startsWith(text);
        });
      } else if (category === "lname") {
        tempConsumers = tempConsumers.filter((consumer) => {
          return consumer.last_name.toLowerCase().startsWith(text);
        });
      } else {
        tempConsumers = tempConsumers.filter((consumer) => {
          return (
            consumer.first_name.toLowerCase().startsWith(text) ||
            consumer.last_name.toLowerCase().startsWith(text) ||
            consumer.email.toLowerCase().startsWith(text)
          );
        });
      }
    }
    return {
      ...state,
      filtered_consumers: tempConsumers,
      error_code: 200,
      consumer_error: false,
    }; //to be coded
  } else if (action.type === LOAD_CONSUMERS) {
    const payload = action.payload;
    return {
      ...state,
      all_consumers: payload.data,
      filtered_consumers: payload.data,
      filters: { ...state.filters },
      error_code: payload.error_code,
      consumer_error: payload.consumer_error,
    };
  } else if (action.type === CLEAR_CONS_FILTER) {
    return {
      ...state,
      filters: { ...state.filters, text: "", category: "all" },
      error_code: 200,
      consumer_error: false,
    }; //to be coded
  } else if (action.type === UPDATE_CONS_FILTER) {
    const { name, value } = action.payload;
    return { ...state, filters: { ...state.filters, [name]: value } };
  } else if (action.type === UNAUTHORIZED) {
    // sessionStorage.setItem("latoken", "");
    return {
      ...state,
      error_code: action.payload.error_code,
      prof_error: action.payload.prof_error,
    };
  }
  //return state
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default consumer_filter_reducer;
