import {
    MODIFY_PRODUCT,
    UPDATE_PROF_ID,
    GET_PROF_DETAIL_SUCCESS,
    GET_PROF_DETAIL_BEGIN,
    GET_PROF_DETAIL_ERROR,
    SAVE_SUCCESS,
    RESET_PROD_STATE
} from '../actions'

const modify_prod_reducer = (state, action) => {
    //const {product, profid} = state
    if (action.type === MODIFY_PRODUCT) {
        const {name,value} = action.payload;
         return {...state, [name]:value, savedStatus: false}
    } else if (action.type === UPDATE_PROF_ID) {
        const id = action.payload.id;
        return {...state, profid:id, savedStatus: false}
    } else if (action.type === GET_PROF_DETAIL_SUCCESS) {
        const profUserData = action.payload
        return {...state,prof_loading: false, profUser: profUserData, product: profUserData.plan, savedStatus: false}
    } else if (action.type === GET_PROF_DETAIL_BEGIN) {
        return {...state, prof_loading: true, savedStatus: false}
    } else if (action.type === GET_PROF_DETAIL_ERROR) {
        return {...state, prof_error: true, savedStatus: false}
    } else if (action.type === SAVE_SUCCESS) {
        return {...state, savedStatus : true}
    } else if (action.type === RESET_PROD_STATE) {
        const {savedStatus} = action.payload
        return {...state, savedStatus: savedStatus}
    } 
    throw new Error(`No Matching "${action.type}" - action type`)
}

export default modify_prod_reducer