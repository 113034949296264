import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/consumer_reducer";

import { GET_CONS_BEGIN, GET_CONS_ERROR, GET_CONS_SUCCESS } from "../actions";

const ConsumerContext = React.createContext();

const initialState = {
  consumer_loading: false,
  consumer_error: false,
  error_code: 200,
  consumers: [],
};

export const ConsumerProvider = ({ children }) => {
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchConsumers = async (url) => {
    const bearer_token = sessionStorage.getItem("latoken");
    dispatch({ type: GET_CONS_BEGIN });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      };
      const response = await axios.get(url, config);
      if (response.status !== undefined) {
        if (response.status === 200) {
          const consumers = await response.data;
          dispatch({ type: GET_CONS_SUCCESS, payload: consumers });
        } else {
          dispatch({
            type: GET_CONS_ERROR,
            payload: { error_code: response.status, consumer_error: true },
          });
        }
      } else {
        dispatch({
          type: GET_CONS_ERROR,
          payload: {
            error_code: response.response.status,
            consumer_error: true,
          },
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: GET_CONS_ERROR,
          payload: { error_code: error.response.status, consumer_error: true },
        });
      } else {
        //Network error
        dispatch({
          type: GET_CONS_ERROR,
          payload: { error_code: 501, consumer_error: true },
        });
      }
    }
  };

  useEffect(() => {
    fetchConsumers(`${restUrl}/consumer`);
  }, []);

  return (
    <ConsumerContext.Provider value={{ ...state, fetchConsumers }}>
      {children}
    </ConsumerContext.Provider>
  );
};

export const useConsumerContext = () => {
  return useContext(ConsumerContext);
};
