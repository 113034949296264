import React from "react";
import { Link } from "react-router-dom";

const ProfessionalRow = ({
  uid,
  name,
  contact_person,
  email,
  plan,
  joined,
  status,
}) => {
  //console.log(consumer)
  return (
    <tr key={uid}>
      <td>{name}</td>
      <td>{contact_person}</td>
      <td>{email}</td>
      <td>{plan}</td>
      <td>
        <Link key={uid} to={`/prodedit/${uid}`} className="btn-large">
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default ProfessionalRow;
